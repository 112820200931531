<template>
  <vs-popup
    class="message-nav-popup"
    :title="lang.botMaker.integrations.title[languageSelected]"
    :active.sync="openR"
    icon-pack="feather"
    icon-close="icon-x"
  >
    <div class="mt-4" v-if="currentApiCall && currentApiCall.url">
      <small>{{ lang.botMaker.integrations.current[languageSelected] }}</small>
      <KonaAlert
        icon="ZapIcon"
        bg-color="rgba(var(--vs-success), 0.15)"
        color="rgba(var(--vs-success), 1)"
        size="small"
      >
        {{ currentApiCall.url }}
        <vs-spacer />
        <vs-button
          radius
          type="border"
          color="success"
          icon="icon-trash"
          icon-pack="feather"
          @click="removeCurrent"
          :disabled="readOnly"
        />
      </KonaAlert>
    </div>

    <KonaTabs>
      <!-- cyberbank apis -->
      <KonaTab
        :name="lang.botMaker.integrations.tabs.cyberbank[languageSelected]"
        :selected="useCyberbank"
        v-if="useCyberbank"
      >
        <KonaAlert
          icon="AlertCircleIcon"
          bg-color="rgba(var(--vs-warning), 0.15)"
          color="rgba(var(--vs-warning), 1)"
          size="small"
        >
          {{ lang.botMaker.integrations.onlyOne[languageSelected] }}
        </KonaAlert>
        <CyberbankIntegrations
          ref="cyberbankApi"
          @close="closeChild"
          @onChange="onCyberbankApiChange"
          :key="cyberbankKey"
        />
      </KonaTab>

      <!-- integrations -->
      <KonaTab
        :name="lang.botMaker.integrations.tabs.integrations[languageSelected]"
        :selected="!useCyberbank"
      >
        <KonaAlert
          icon="AlertCircleIcon"
          bg-color="rgba(var(--vs-warning), 0.15)"
          color="rgba(var(--vs-warning), 1)"
          size="small"
        >
          {{ lang.botMaker.integrations.onlyOne[languageSelected] }}
        </KonaAlert>
        <IntegrationApi
          ref="integrationApi"
          @open="openChild"
          @close="closeChild"
          :key="integrationKey"
          @onChange="onApisChange"
        />
        <!-- @addApi="addApi"
          @removeApi="removeApi" -->
      </KonaTab>
      <KonaTab
        :name="lang.botMaker.integrations.survey[languageSelected]"
        :selected="false"
      >
        <IntegrationSurvey ref="IntegrationSurvey" @close="closeChild" />
      </KonaTab>
    </KonaTabs>

    <!-- actions -->
    <vs-row
      vs-justify="flex-end"
      class="pb-6 pt-6"
      vs-align="center"
      vs-type="flex"
      vs-w="12"
      v-if="!readOnly"
    >
      <vs-button @click="closeChild" color="danger" type="flat">
        {{ lang.botMaker.integrations.cancel[languageSelected] }}
      </vs-button>
      <vs-button class="ml-4" @click="updateIntegrations">
        {{ lang.botMaker.integrations.accept[languageSelected] }}
      </vs-button>
    </vs-row>
  </vs-popup>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import BotMakerService from '@/services/botMaker.service'

export default {
  name: 'IntegrationPopup',
  components: {
    IntegrationSurvey: () => import('./Integration/IntegrationSurvey.vue'),
    IntegrationApi: () => import('./Integration/IntegrationApi.vue'),
    CyberbankIntegrations: () =>
      import('@/components/CyberbankIntegrations.vue'),
    KonaTabs: () => import('@/components/kona-tabs/KonaTabs.vue'),
    KonaTab: () => import('@/components/kona-tabs/KonaTab.vue'),
    KonaAlert: () => import('@/components/KonaAlert.vue')
  },
  props: {
    open: Boolean,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openR: false,
      integrationApis: [],
      cyberbankApi: [],
      integrationKey: 0,
      cyberbankKey: 0,
      currentApiCall: null
    }
  },
  watch: {
    botMessageInEdit() {
      this.findApiCall()
    },
    userMessageInEdit() {
      this.findApiCall()
    },
    openR() {
      this.findApiCall()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'useCyberbank']),
    ...mapGetters('botMaker', [
      'botMakerMessages',
      'userMessageInEdit',
      'botMessageInEdit',
      'getMessageById',
      'currentDialogHasJumps',
      'hasChildren',
      'apiCalls',
      'dialogsMap',
      'integrationsByDialogId',
      'getApiCallById'
    ])
  },
  methods: {
    ...mapMutations('botMaker', [
      'SET_MESSAGE_IN_EDIT',
      'RESET_USER_MESSAGE',
      'RESET_CONDITIONS'
    ]),
    ...mapActions('botMaker', [
      'SAVE_BOT_MESSAGE',
      // 'UPDATE_DIALOG_INTEGRATIONS',
      'GET_DIALOGS',
      'UPDATE_DIALOG_SURVEYS'
    ]),
    openChild() {
      this.openR = true
      this.$refs.IntegrationSurvey.open()
      this.$emit('update:open', true)
    },
    closeChild() {
      this.openR = false
      this.$emit('update:open', false)
    },
    openIntegrationsPopup() {
      this.$refs.integrationApi.openIntegrationsPopup()
    },
    updateIntegrations() {
      this.updateApis()
      this.updateSurvey()
    },
    updateApis() {
      this.$vs.loading()

      let dialogSavedId = this.userMessageInEdit.isEditing
        ? this.userMessageInEdit.dialogSavedId
        : this.botMessageInEdit.dialogSavedId

      // if no existing message selected create empty text message to add integration
      if (!dialogSavedId) {
        const messageId = this.botMessageInEdit.id
        this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
          .then(() => {
            const createdMessage = this.getMessageById(messageId)
            dialogSavedId = createdMessage.dialogSavedId
            this.SET_MESSAGE_IN_EDIT()
            this.RESET_USER_MESSAGE()
            this.RESET_CONDITIONS()
          })
          .catch(error => {
            console.warn(error)
          })
          .finally(() => {
            this.$vs.loading.close()
          })
      }

      const getDialogsId =
        this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId

      const api = this.integrationApis[0]
        ? this.integrationApis[0]
        : this.cyberbankApi[0]
        ? this.cyberbankApi[0]
        : ''

      const dialogSavedId2 =
        (this.userMessageInEdit && this.userMessageInEdit.dialogSavedId) ||
        (this.botMessageInEdit && this.botMessageInEdit.dialogSavedId)

      const responses = this.updateBeforeAPICallResponses()

      BotMakerService.updateDialog(dialogSavedId, {
        apiCall: api,
        responses: responses
      })

        // // this.UPDATE_DIALOG_INTEGRATIONS({
        //   dialogSavedId,
        //   api: api
        // })
        .then(async () => {
          await this.GET_DIALOGS(dialogSavedId2 || getDialogsId)
          this.SET_MESSAGE_IN_EDIT()
          this.RESET_USER_MESSAGE()
          this.RESET_CONDITIONS()
          this.$vs.notify({
            color: 'success',
            text: this.lang.botMaker.integrations.update.integrationsApis
              .success.text[this.languageSelected]
          })
        })
        .catch(error => {
          this.$log.error(error)
          this.$vs.notify({
            color: 'danger',
            text: this.lang.botMaker.integrations.update.integrationsApis.error
              .title[this.languageSelected]
          })
        })
        .finally(() => {
          this.$vs.loading.close()
          this.closeChild()
        })
    },
    // TODO: join with updateApis method to avoid 2 calls
    updateSurvey() {
      const hasJump = this.currentDialogHasJumps
      if (hasJump) {
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.errorSurvey[this.languageSelected],
          color: 'danger'
        })
        return
      }
      if (this.hasChildren) {
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.hasChildrenSurvey[this.languageSelected],
          color: 'danger'
        })
        return
      }

      const sendSurveySettings = JSON.parse(
        JSON.stringify(this.$refs.IntegrationSurvey.getValue())
      )

      if (sendSurveySettings.error) return
      if (
        sendSurveySettings &&
        !sendSurveySettings.surveyEnabled &&
        !sendSurveySettings.survey
      ) {
        this.$vs.notify({
          color: 'success',
          text: this.lang.botMaker.integrations.update.integrationsApis.success
            .text[this.languageSelected]
        })
        return
      }
      this.$vs.loading()

      let dialogSavedId = this.userMessageInEdit.isEditing
        ? this.userMessageInEdit.dialogSavedId
        : this.botMessageInEdit.dialogSavedId

      // if no existing message selected create empty text message to add integration
      if (!dialogSavedId) {
        const messageId = this.botMessageInEdit.id
        this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
          .then(() => {
            const createdMessage = this.getMessageById(messageId)
            dialogSavedId = createdMessage.dialogSavedId
            this.SET_MESSAGE_IN_EDIT()
            this.RESET_USER_MESSAGE()
            this.RESET_CONDITIONS()
          })
          .catch(error => {
            console.warn(error)
          })
          .finally(() => {
            this.$vs.loading.close()
          })
      }

      const getDialogsId =
        this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId

      const dialogSavedId2 =
        (this.userMessageInEdit && this.userMessageInEdit.dialogSavedId) ||
        (this.botMessageInEdit && this.botMessageInEdit.dialogSavedId)

      this.UPDATE_DIALOG_SURVEYS({
        dialogSavedId,
        sendSurveySettings
      })
        .then(async () => {
          await this.GET_DIALOGS(dialogSavedId2 || getDialogsId)
          this.SET_MESSAGE_IN_EDIT()
          this.RESET_USER_MESSAGE()
          this.RESET_CONDITIONS()
          this.$vs.notify({
            color: 'success',
            text: this.lang.botMaker.integrations.update.surveys.success.text[
              this.languageSelected
            ]
          })
        })
        .catch(error => {
          this.$log.error(error)
          this.$vs.notify({
            color: 'danger',
            text: this.lang.botMaker.integrations.update.surveys.error.title[
              this.languageSelected
            ]
          })
        })
        .finally(() => {
          this.$vs.loading.close()
          this.closeChild()
        })
    },
    // addApi(apiId) {
    //   this.cyberbankApi = null
    //   this.cyberbankKey++
    //   this.integrationApis.push(apiId)
    // },
    // removeApi(index) {
    //   this.integrationApis.splice(index, 1)
    // },
    onCyberbankApiChange(apis) {
      this.integrationApis = []
      this.integrationKey++
      this.cyberbankApi = apis
    },
    onApisChange(apis) {
      this.cyberbankApi = []
      this.cyberbankKey++
      this.integrationApis = apis
    },
    removeCurrent() {
      this.currentApiCall = null
      this.integrationApis = []
      this.integrationKey++
      this.cyberbankApi = []
      this.cyberbankKey++
    },
    findApiCall() {
      const apiCall = this.apiCalls.find(ac => {
        // const dialogApiCall =
        //   this.botMessageInEdit &&
        //   this.botMessageInEdit.dialogId &&
        //   this.dialogsMap[this.botMessageInEdit.dialogId] &&
        //   this.dialogsMap[this.botMessageInEdit.dialogId].apiCall
        const dialogId =
          (this.botMessageInEdit && this.botMessageInEdit.dialogId) ||
          (this.userMessageInEdit && this.userMessageInEdit.dialogId)

        const dialogApiCall =
          this.dialogsMap[dialogId] && this.dialogsMap[dialogId].apiCall

        return ac._id === dialogApiCall
      })
      if (apiCall) {
        this.currentApiCall = apiCall
        if (apiCall.type === 'cyberbank') {
          this.cyberbankApi = [apiCall._id]
        } else {
          this.integrationApis = [apiCall._id]
        }
      } else {
        this.integrationApis = []
        this.cyberbankApi = []
        this.currentApiCall = null
      }
    },
    updateBeforeAPICallResponses() {
      const dialogId = this.botMessageInEdit.isEditing
        ? this.botMessageInEdit.dialogId
        : this.userMessageInEdit.dialogId

      const currentDialog = this.dialogsMap[dialogId]

      let dialogCopy = {}
      Object.assign(dialogCopy, currentDialog)

      const selectedResponseId =
        this.botMessageInEdit && this.botMessageInEdit.type._id
      const selectedResponseIndex = dialogCopy.responses.findIndex(
        r => r._id === selectedResponseId
      )

      dialogCopy.responses.forEach((response, index) => {
        // response.beforeAPICall = response.beforeAPICall
        //   ? response.beforeAPICall
        //   : true
        response.beforeAPICall = index <= selectedResponseIndex ? true : false
      })

      return dialogCopy.responses
    }
  },
  mounted() {
    this.openR = this.open
    if (this.openR == true) {
      this.$refs.IntegrationSurvey.open()
    }
    this.findApiCall()
  }
}
</script>
